import React, { useState, useRef } from "react"
import { graphql } from "gatsby"
import { Container, Heading, Box } from "@theme-ui/components"
import Layout from "../components/layout"
import { getHomePath } from "../utils/path"
import { HelmetDatoCms } from "gatsby-source-datocms"
import LatestArticles from "../components/latestArticles"
import { useFavicon } from "../hooks/useFavicon"
import ItemCarousel from "../components/blocks/itemCarousel"
import ImageAndText from "../components/blocks/imageAndText"
import NumbersCollection from "../components/blocks/numbersCollections"
import PageCarousel from "../components/blocks/pageCarousel"
import VerticalLines from "../components/verticalLines"
import SidebarDots from "../components/sidebarDots"
import ContactFooter from "../components/contactFooter"
import { useParallax } from "react-scroll-parallax"

const Home = ({ data: { page, site, articles } }) => {
  const favicon = useFavicon().site.faviconMetaTags
  const [activeSection, setActiveSection] = useState(null)

  var blockIds = page.content.map(block => ({
    id: block.id,
    bg:
      block.model.apiKey === "page_carousel"
        ? "light"
        : block.model.apiKey === "item_carousel"
        ? "gray"
        : block.model.apiKey === "numbers_collection"
        ? "light"
        : "gray",
    shortTitle: block.shortTitle,
  }))

  blockIds.push({
    id: "footer",
    bg: "light",
    shortTitle: page.locale === "en" ? "Contacts" : (page.locale === "it" ? "Contatti" : "Contacts"),
  })

  const i18nPaths = site.locales.map(locale => {
    return {
      locale: locale,
      value: getHomePath(locale),
    }
  })

  return (
    <Layout locale={page.locale} i18nPaths={i18nPaths} home={true}>
      <HelmetDatoCms seo={page.seoMetaTags} favicon={favicon}>
        <html lang={page.locale} />
      </HelmetDatoCms>
      <SidebarDots blocks={blockIds} />
      <VerticalLines />

      {page.content.map(block => (
        <Box as="section" key={block.id} id={block.id} sx={{ zIndex: 0 }}>
          {block.model.apiKey === "page_carousel" && (
            <PageCarousel title={block.title} pages={block.pages} />
          )}
          {block.model.apiKey === "item_carousel" && (
            <Box
              sx={{
                minHeight: ["auto","100vh"],
                height: ["100%", "100%"],
                position: "relative",
              }}
            >
              <ItemCarousel title={block.title} link={block.link}  items={block.items} />
            </Box>
          )}
          
          {block.model.apiKey === "numbers_collection" && (
            <NumbersCollection
              title={block.title}
              description={block.description}
              image={block.image}
              video={block.video}
              first={block.video && true}
              numbers={block.numbers}
              link={block.link}
            />
          )}
          {block.model.apiKey === "image_and_text" && (
            <ImageAndText
              label={block.content.label}
              subtitle={block.content.subtitle}
              title={block.content.title}
              body={block.content.body}
              image={block.image}
              link={block.content.link}
            />
          )}
        </Box>
      ))}
      <ContactFooter />
    </Layout>
  )
}

export default Home

export const query = graphql`
  query HomeQuery($id: String!, $locale: String!) {
    page: datoCmsHomePage(id: { eq: $id }) {
      id
      title
      locale
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      content {
        ... on DatoCmsNumbersCollection {
          id
          title
          shortTitle
          description
          image {
            gatsbyImageData(
              width: 1480
              placeholder: BLURRED
              forceBlurhash: false
              imgixParams: {
                blendColor: "#212C30"
                blendMode: "multiply"
                blendAlpha: 65
              }
            )
          }
          link {
            ... on DatoCmsInternalLink {
              id
              anchor
              locale
              model {
                apiKey
              }
              link {
                ... on DatoCmsPage {
                  ...PageDetails
                  ...PageTreeParent
                  ...AllSlugLocales
                }
                ... on DatoCmsArticle {
                  ...ArticleDetails
                  ...ArticleAllSlugLocales
                }
                
              }
            }
          }
          video {
            url
          }
          numbers {
            legend
            float
            suffix
            prefix
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsItemCarousel {
          id
          shortTitle
          title
          items {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          link {
            ... on DatoCmsInternalLink {
              id
              anchor
              locale
              model {
                apiKey
              }
              link {
                ... on DatoCmsPage {
                  ...PageDetails
                  ...PageTreeParent
                  ...AllSlugLocales
                }
                ... on DatoCmsArticle {
                  ...ArticleDetails
                  ...ArticleAllSlugLocales
                }
                
              }
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsPageCarousel {
          id
          title
          shortTitle
          pages {
            ... on DatoCmsPage {
              ...PageDetails
              ...PageTreeParent
              ...AllSlugLocales
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsImageAndText {
          id
          shortTitle
          content {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          image {
            gatsbyImageData(
              width: 960
              placeholder: BLURRED
              forceBlurhash: false
              imgixParams: { fit: "crop", ar: "4:3", h: "840" }
            )
          }
          model {
            apiKey
          }
        }
      }
    }
    articles: allDatoCmsArticle(
      sort: { fields: meta___firstPublishedAt, order: DESC }
      filter: { slug: { ne: null }, locale: { eq: $locale } }
      limit: 6
    ) {
      nodes {
        ...ArticleDetails
        ...ArticleAllSlugLocales
        ...ArticleMeta
      }
    }
    site: datoCmsSite {
      locales
    }
  }
`
